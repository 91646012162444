import React from 'react';
import { Link } from "gatsby";
import Layout from '../components/layout/Layout';
import Error from '../svg/undraw_page_not_found_re_e9o6.svg';
import Button from '../components/Button';


const NotFound = () => (
  <Layout>
    <section id="404" className="">
      <div className="container text-center mx-auto">
        <Error className="mx-auto w-1/3" />
        <h2 className="text-xl">Sorry, this is not what you were looking for!</h2>
        <Link to=""><Button className="text-xl mt-4">Go Home</Button></Link>
      </div>
    </section>
  </Layout>
);

export default NotFound;
